import Typography from "@suid/material/Typography";
import DialogBase from "@suid/material/Dialog";
import styled from "@suid/material/styles/styled";

type StyledTypography = typeof Typography;
type StyledDialog = typeof DialogBase;

export const Heading: StyledTypography = styled( Typography )( ( { flex: 1 } ) );

export const Dialog: StyledDialog = styled( DialogBase )( ( { theme } ) => ( {
	[ theme.breakpoints.between( [ 'xs', 'sm' ] ) ]: {
		width: '100%',
		height: '100%'
	}
} ) );
