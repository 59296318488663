import { ShopifyAuthForm } from '../forms/ShopifyAuth';
import { createSignal } from 'solid-js';

export type Cookie = { name: string, path: string, expires: string, httpOnly: boolean, site: string, secure: string; };
export type ShopifyAuthResponse = { success: true | false, message?: string; };

type ShopifyAuthInstance = {
	readonly url: string;
	post: ( form: ShopifyAuthForm ) => Promise<ShopifyAuthResponse>;
};

const headers = { 'Content-Type': "application/x-www-form-urlencoded" };
type Pair<T> = [ T, T ];

const fromObjectEntry = ( data: string, [ key, value ]: Pair<string>, index: number ) => (
	data.concat( ( index > 0 && "&" || "" ).concat( key.concat( "=".concat( value ) ) ) ) );

const ShopifyAuthRequest = (): ShopifyAuthInstance => {
	const parser: DOMParser = new DOMParser;
	const [ address, setAddress ] = createSignal<string>( window.location.href );

	const locationHasChanged = ( origin: string ): boolean =>
		( ( url: string ) => url.localeCompare( origin ) !== 0 )( document.location.toString() );

	return ( {
		async post ( data: ShopifyAuthForm ): Promise<ShopifyAuthResponse> {
			let message: string | undefined = undefined;

			const body: string = Object
				.entries( { form_type: "storefront_password", utf8: "✓", 'redirect_to': '/', ...data } )
				.reduce( fromObjectEntry, "" );

			const response: Response = await fetch( address(), { method: 'POST', headers, body } );

			const success: boolean =
				response.status === 200 &&
				response.redirected &&
				locationHasChanged( response.url );

			if ( success ) {
				setAddress( response.url );
			}
			else Array.from( parser
				.parseFromString( await response.text(), 'text/html' )
				.querySelectorAll<HTMLLIElement>( 'form#shopify-auth li' ) )
				.forEach( ( el: HTMLLIElement ) => (
					message = el.innerText.trim()
				) );

			return { success, message };
		},
		get url (): string {
			return address();
		}
	} ) satisfies ShopifyAuthInstance;
};

export default ShopifyAuthRequest;
