import Typography from "@suid/material/Typography";
import styled from "@suid/material/styles/styled";

type ParagraphElement = typeof Typography;

const Paragraph: ParagraphElement = styled(Typography)(
	({ props: { fontSize = "1.2rem" } }) => ({
		textAlign: "justify",
		fontSize,
	}),
);

export default Paragraph;
